import { format } from '@msdyn365-commerce-modules/utilities';
import { IComponent, ICoreContext, IComponentProps, IGridSettings, IImageSettings, Image } from '@msdyn365-commerce/core';
import {
    AttributeValue,
    ProductPrice,
    ProductSearchResult,
    ProductDimensionValue,
    ProductDimension
} from '@msdyn365-commerce/retail-proxy';
import * as React from 'react';
import { VendorLogo } from '../../utilities/vendor-logo/vendor-logo';
import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { getDimensionValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import { checkImage } from '../../utilities/helper-functions';
import { PriceComponent } from '@msdyn365-commerce/components';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
}
export interface IProductComponent extends IComponent<IProductComponentProps> {}

interface IState {
    swatches: ProductDimensionValue[];
    isVendorLogoAvailable: boolean;
    vendorLogo: string;
}

interface IColorSwatch {
    colorId: string;
    colorName: string;
}
/**
 *
 * ProductCard component
 * @extends {React.PureComponent<>}
 */
export class ProductCard extends React.PureComponent<IProductComponentProps, IState> {
    constructor(props: IProductComponentProps) {
        super(props);
        this.state = {
            swatches: [],
            isVendorLogoAvailable: false,
            vendorLogo: ''
        };
    }

    public componentDidUpdate = async (prevProps: IProductComponentProps) => {
        if (this.props.data !== prevProps.data) {
            await this.getDataOnUpdate();
        }
    };

    public componentDidMount = async () => {
        await this.getDataOnUpdate();
    };

    public render(): JSX.Element | null {
        const { data, context, imageSettings, ratingAriaLabel } = this.props;

        const product = data.product;
        if (!product) {
            return null;
        }
        const discountedPriceRangeAttribute = this._getAttributeValue('DiscountPriceRange', product);
        const discountedPriceRangesValue =
            discountedPriceRangeAttribute && discountedPriceRangeAttribute.TextValue ? discountedPriceRangeAttribute.TextValue : '';
        const discount = discountedPriceRangesValue ? true : false;
        const discountedPriceRanges = discountedPriceRangesValue.split('-');
        const discountedHigherPrice =
            discountedPriceRanges.length === 2 ? context.cultureFormatter.formatCurrency(+discountedPriceRanges[1]) : undefined;

        const productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);
        return (
            <a
                href={productUrl}
                aria-label={this.renderLabel(
                    product.Name,
                    context.cultureFormatter.formatCurrency(product.Price),
                    product.AverageRating,
                    ratingAriaLabel
                )}
                className='msc-product'
            >
                <div className='msc-product__image position-relative'>
                    {this.renderProductPlacementImage(imageSettings, context.request.gridSettings, product.PrimaryImageUrl, product.Name)}
                    {this.state.isVendorLogoAvailable && this.state.vendorLogo && <VendorLogo logoUrl={this.state.vendorLogo} />}
                </div>
                <div className='msc-product__details'>
                    <h4 className='msc-product__title'>{product.Name}</h4>

                    {this.renderDescription(product.Description)}
                    {this.renderPriceRange()}
                    {this.renderSwatches(context.request.apiSettings.baseImageUrl)}
                    {discount && discountedHigherPrice && <div className='mands-sale-discount-text'>Sale on selected items</div>}
                    {discount && !discountedHigherPrice && <div className='mands-sale-discount-text'>Sale</div>}
                </div>
            </a>
        );
    }

    private renderLabel = (name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string => {
        let ratingLabel = '';
        name = name || '';
        price = price || '';
        if (rating) {
            const roundedRating = rating.toFixed(2);
            ratingLabel = format(ratingAriaLabel || '', roundedRating, '5');
        }
        return `${name} ${price} ${ratingLabel}`;
    };

    private renderDescription = (description?: string): JSX.Element | null => {
        return <p className='msc-product__text'>{description}</p>;
    };

    private renderProductPlacementImage = (
        imageSettings?: IImageSettings,
        gridSettings?: IGridSettings,
        imageUrl?: string,
        altText?: string
    ): JSX.Element | null => {
        if (!imageUrl || !gridSettings || !imageSettings) {
            return null;
        }
        const img = {
            src: imageUrl,
            altText: altText ? altText : ''
        };
        const imageProps = {
            gridSettings: gridSettings,
            imageSettings: imageSettings
        };
        return <Image {...img} {...imageProps} loadFailureBehavior='empty' />;
    };

    private getDataOnUpdate = async () => {
        const {
            data: { product },
            context
        } = this.props;
        const itemId = product ? product.ItemId : undefined;
        const apiSettings = context && context.request ? context.request.apiSettings : undefined;
        const updatedItemId = itemId?.replace('/', ':');
        const vendorImageUrl =
            apiSettings && apiSettings.baseImageUrl
                ? `${apiSettings.baseImageUrl.substring(0, apiSettings.baseImageUrl.length - 1)}${updatedItemId}__logo.png`
                : '';
        if (product) {
            const recordId = product.RecordId;
            const channelId = context.actionContext.requestContext.apiSettings.channelId;
            const callerContext = context.actionContext;
            const matchingDimensionValues: ProductDimension[] = [];

            checkImage(vendorImageUrl)
                .then(() => this.setState({ isVendorLogoAvailable: true, vendorLogo: vendorImageUrl }))
                .catch(() => this.setState({ isVendorLogoAvailable: false, vendorLogo: vendorImageUrl }));
            // DimensionTypeValue = 1, for Color
            const dimensionTypeValue: number = 1;
            const swatches = await getDimensionValuesAsync(
                { callerContext, queryResultSettings: {} },
                recordId,
                channelId,
                dimensionTypeValue,
                matchingDimensionValues,
                // @ts-ignore: KitVariantResolution context should be nullable
                null
            );
            this.setState({ swatches });
        }
    };
    private renderSwatches = (baseImageUrl: string) => {
        const { swatches } = this.state;
        const arr: IColorSwatch[] = [];
        if (swatches.length > 0) {
            swatches.map((dimensionValue: ProductDimensionValue) => {
                if (dimensionValue) {
                    arr.push({ colorName: dimensionValue.Value!, colorId: dimensionValue.DimensionId! });
                }
            });
        }
        return (
            <div className='plp-colorswatch-wrap'>
                {arr.length > 0 && (
                    <div>
                        <ul>
                            {arr.map((swatch, index) => {
                                const backgroundImageUrl = `url(${baseImageUrl}Products/${swatch.colorId}.png)`;
                                return (
                                    <li
                                        key={index}
                                        className={`uniform-image-color`}
                                        role='button'
                                        style={{ backgroundImage: backgroundImageUrl, backgroundColor: '#FFF' }}
                                    />
                                );
                            })}
                        </ul>
                        <span className='color-swatch-text'>{arr.length === 1 ? arr[0].colorName : `${arr.length} colours available`}</span>
                    </div>
                )}
            </div>
        );
    };

    private renderPrice = (
        context: ICoreContext,
        typeName: string,
        id: string,
        basePrice?: number,
        adjustedPrice?: number,
        savingsText?: string,
        freePriceText?: string,
        originalPriceText?: string,
        currentPriceText?: string
    ): JSX.Element | null => {
        const price: ProductPrice = {
            BasePrice: basePrice,
            AdjustedPrice: adjustedPrice,
            CustomerContextualPrice: adjustedPrice
        };

        return (
            <PriceComponent
                context={context}
                id={id}
                typeName={typeName}
                data={{ price: price }}
                savingsText={savingsText}
                freePriceText={freePriceText}
                originalPriceText={originalPriceText}
            />
        );
    };

    private renderPriceRange = (): JSX.Element | null => {
        const {
            data: { product },
            context: { cultureFormatter },
            id,
            typeName,
            context,
            savingsText,
            freePriceText,
            originalPriceText,
            currentPriceText
        } = this.props;

        const priceRangeAttribute = this._getAttributeValue('PriceRange', product);
        const discountedPriceRangeAttribute = this._getAttributeValue('DiscountPriceRange', product);
        const priceRanges = priceRangeAttribute && priceRangeAttribute.TextValue ? priceRangeAttribute.TextValue.split('-') : [];
        const discountedPriceRangesValue =
            discountedPriceRangeAttribute && discountedPriceRangeAttribute.TextValue ? discountedPriceRangeAttribute.TextValue : '';
        const discountedPriceRanges = discountedPriceRangesValue ? discountedPriceRangesValue.split('-') : [];
        const lowerPrice = priceRanges.length > 0 ? cultureFormatter.formatCurrency(+priceRanges[0]) : undefined;
        const higherPrice = priceRanges.length === 2 ? cultureFormatter.formatCurrency(+priceRanges[1]) : undefined;

        const discountedLowerPrice =
            discountedPriceRanges.length > 0 ? cultureFormatter.formatCurrency(+discountedPriceRanges[0]) : undefined;
        const discountedHigherPrice =
            discountedPriceRanges.length === 2 ? cultureFormatter.formatCurrency(+discountedPriceRanges[1]) : undefined;
        if (!product) {
            return null;
        }
        const discount = discountedPriceRangesValue ? true : false;
        return (
            <div className='price-range-container'>
                {discount ? (
                    <div className='mands-discounted-price'>
                        {discountedLowerPrice && <span className='mands-discounted-lower-price'>{discountedLowerPrice}</span>}
                        {discountedHigherPrice && <span className='mands-discounted-higher-price'> - {discountedHigherPrice}</span>}
                    </div>
                ) : (
                    <div className='mands-actual-price'>
                        {priceRanges.length !== 2 ? (
                            this.renderPrice(
                                context,
                                typeName,
                                id,
                                product.BasePrice,
                                product.Price,
                                savingsText,
                                freePriceText,
                                originalPriceText,
                                currentPriceText
                            )
                        ) : (
                            <>
                                <span className='mands-actual-lower-price'>{lowerPrice}</span>
                                {higherPrice && <span className='mands-actual-higher-price'> - {higherPrice}</span>}
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    };

    private _getAttributeValue(attributeName: string, product: ProductSearchResult | undefined): AttributeValue | undefined {
        if (attributeName.length > 0 && product) {
            return product && product.AttributeValues && product.AttributeValues.find(x => x.Name === attributeName);
        }
        return undefined;
    }
}

export default ProductCard;
