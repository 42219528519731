import * as React from 'react';
import { IVendorLogoProps } from './vendor-logo.props';

/**
 *
 * VendorLogo Component is used to add vendor logo on products
 * @extends {React.PureComponent<IVendorLogoProps>}
 */
export class VendorLogo extends React.PureComponent<IVendorLogoProps> {
    constructor(props: IVendorLogoProps) {
        super(props);
    }
    public render(): JSX.Element {
        const { logoUrl } = this.props;
        return (
            <div className='vendor-logo-container'>
                <img src={logoUrl} alt='vendor logo' />
            </div>
        );
    }
}
